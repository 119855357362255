
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ClaimNatures from "@/views/reusable/claims/motor-insurance/claim-natures.json";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "cooperative-motor-insurance-claims-edit",
  components: { Form, Field, ErrorMessage, ImagesUploader },
  props: ["publicId"],
  data() {
    return {
      icClass: "col-xl-12",
      ttgClass: "col-xl-4",
      hprClass: "col-xl-4",
      fbClass: "col-xl-6",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      hasBeenTakenToGarage: false,
      hasLicence: false,
      hasThirdPartyInfo: false,
      hasGarageInfo: false,
      isALicensedDriver: false,
      // publicId: this.$route.params.publicId,
      claimNatures: ClaimNatures,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      todaysDate: "",
      locationOfIncident: "",
      policeReport: "",
      takenToGarage: "",
      licensedDriver: "",
      weatherCondition: "",
      accidentImage: "",
      descriptionOfIncident: "",
      thirdPartyFullName: "",
      thirdPartyPhoneNumber: "",
      thirdPartyEyeWitness: "",
      thirdPartyDriverLicense: "",
      thirdPartyInsuranceCertificate: "",
      thirdPartyOtherDocuments: "",
      acceptTerms: false,
      garageName: "",
      garagePhoneNumber: "",
      amount: "",
      amountImage: "",
      garageAddress: "",
      damagedVehicleImages: "",
      policy: "",
      random: "",
      policies: [],
      policyDetails: [],
      schedules: [],
      role: "",
      route: "",
      policyItemRoute: "",
      policyStartDate: "",
      minimumDate: ""
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      itemPublicId: Yup.mixed().required().label("Accidented vehicle"),
      claimNature: Yup.mixed().required().label("Accidented vehicle"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      locationOfIncident: Yup.string().required().label("Location of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident")
      // damagedVehicleImages: Yup.mixed()
      //   .required()
      //   .label("Upload damaged vehicles"),
      // garagePhoneNumber: Yup.number()
      //   .min(11)
      //   .required()
      //   .label("Garage phone number"),
      // acceptTerms: Yup.boolean()
      //   .oneOf([true])
      //   .required()
      //   .label("Terms & conditions"),
    });

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
              if (Array.isArray(value)) {
                value.forEach((itemValue, index) => {
                  formData.append(
                    `${key}[${index}]`,
                    itemValue as string | Blob
                  );
                });
              } else {
                formData.append(key, value as string | Blob);
              }
            }

            ApiService.setHeader();
            ApiService.post(
              `${variables.COOPERATIVE_CLAIMS_ROUTE}/${props.publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Claims",
        "Report Claims",
        "Motor Insurance Claim"
      ]);
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.role = store.getters.currentUser.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    // Get the associated claim information
    this.getClaimInformation();

    //Set page title
    document.title =
      "Edit Motor Insurance Claim | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getUmbrellaPolicyInformation(value) {
      this.policies.forEach((item) => {
        if (item["publicId"] === value) {
          this.schedules = item["policies"];
          this.minimumDate = item["policies"][0]["startDate"];
        }
      });
    },

    firstLoad(umbrellaPolicy, policyId, itemPublicId) {
      this.policies.forEach((item) => {
        if (item["publicId"] === umbrellaPolicy) {
          this.schedules = item["policies"];
          this.minimumDate = item["policies"][0]["startDate"];
        }

        if (this.schedules != ("" as any)) {
          this.random = umbrellaPolicy;
          this.policy = policyId;
          this.itemPublicId = itemPublicId;
        }
      });
    },
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual claim policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.COOPERATIVE_CLAIMS_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to variables
            this.claimNature = data.data.metadata.claimNature;
            this.firstLoad(
              data.data.metadata.random,
              data.data.metadata.policy,
              data.data.metadata.itemPublicId
            );
            // this.itemPublicId = data.data.metadata.policy;
            this.lossDate = data.data.metadata.lossDate;
            this.amount = data.data.metadata.amount;
            this.locationOfIncident = data.data.metadata.locationOfIncident;
            this.licensedDriver = data.data.metadata.licensedDriver;
            this.weatherCondition = data.data.metadata.weatherCondition;
            this.descriptionOfIncident =
              data.data.metadata.descriptionOfIncident;
            this.garageName = data.data.metadata.garageName;
            this.thirdPartyFullName = data.data.metadata.thirdPartyFullName;
            this.thirdPartyPhoneNumber =
              data.data.metadata.thirdPartyPhoneNumber;
            this.thirdPartyEyeWitness = data.data.metadata.thirdPartyEyeWitness;
            this.thirdPartyDriverLicense =
              data.data.metadata.thirdPartyDriverLicense;
            this.thirdPartyInsuranceCertificate =
              data.data.metadata.thirdPartyInsuranceCertificate;
            this.thirdPartyOtherDocuments =
              data.data.metadata.thirdPartyOtherDocuments;
            this.garageAddress = data.data.metadata.garageAddress;
            this.garagePhoneNumber = data.data.metadata.garagePhoneNumber;
            this.takenToGarage = data.data.metadata.takenToGarage;
            this.policyStartDate = data.data.policyStartDate;
            // policeReport: "",
            // accidentImage: "",
            // amountImage: "",
            // damagedVehicleImages: "",
            if (data.data.lossType == "Accident") {
              this.hasLicence = true;
              this.hasGarageInfo = true;
              this.hasBeenTakenToGarage = true;
            } else if (data.data.lossType == "Fire Damage") {
              this.hasBeenTakenToGarage = true;
              this.hasGarageInfo = true;
              this.icClass = "col-xl-12";
              this.ttgClass = "col-xl-12";
            } else if (data.data.lossType == "Third-Party Incident") {
              this.hasPoliceReport = true;
              this.hasThirdPartyInfo = true;
              this.hasLicence = true;
            } else if (data.data.lossType == "Stolen(Theft)") {
              this.hasPoliceReport = true;
              this.icClass = "col-xl-12";
              this.hprClass = "col-xl-12";
            } else if (data.data.lossType == "Vandalised") {
              this.hasPoliceReport = true;
              this.hasBeenTakenToGarage = true;
              this.hasGarageInfo = true;
              this.hprClass = "col-xl-6";
              this.ttgClass = "col-xl-6";
            } else {
              this.defaultValues();
            }

            // setTimeout(() => {
            // this.random = data.data.metadata.random;
            // this.itemPublicId = data.data.metadata.itemPublicId;
            // }, 2000);
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            console.log(error);
            router.go(-1);
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.COOPERATIVE_UMRELLA_POLCIES_ROUTE}/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    setPolicyId(event) {
      this.policy =
        event.target.options[event.target.selectedIndex].dataset.policy;
    },
    getClaimNature(event) {
      this.defaultValues();

      const code =
        event.target.options[event.target.selectedIndex].dataset.code;
      // If the claim nature is accident
      if (code == "AC") {
        this.hasLicence = true;
        this.hasBeenTakenToGarage = true;
      } else if (code == "FD") {
        this.hasBeenTakenToGarage = true;
        this.hasFireBrigadeReport = true;
        this.hasGarageInfo = true;
        this.icClass = "col-xl-12";
        this.ttgClass = "col-xl-6";
        this.fbClass = "col-xl-6";
      } else if (code == "TPI") {
        this.hasPoliceReport = true;
        this.hasThirdPartyInfo = true;
        this.hasLicence = true;
      } else if (code == "ST") {
        this.hasPoliceReport = true;
        this.icClass = "col-xl-12";
        this.hprClass = "col-xl-12";
      } else if (code == "VD") {
        this.hasPoliceReport = true;
        this.hasBeenTakenToGarage = true;
        this.hprClass = "col-xl-6";
        this.ttgClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      this.todaysDate = variables.currentDate();
    },
    getGarageChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "Yes") {
        this.hasGarageInfo = true;
      }
    },
    getLicenseChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "No") {
        this.isALicensedDriver = true;
      } else {
        this.isALicensedDriver = false;
      }
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.ttgClass = "col-xl-4";
      this.hprClass = "col-xl-4";
      this.fbClass = "col-xl-6";

      this.hasPoliceReport = false;
      this.hasBeenTakenToGarage = false;
      this.hasLicence = false;
      this.hasThirdPartyInfo = false;
      this.hasGarageInfo = false;
      this.isALicensedDriver = false;
      this.hasFireBrigadeReport = false;

      this.takenToGarage = "";
      this.licensedDriver = "";
      this.weatherCondition = "";
      this.thirdPartyEyeWitness = "";
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    },
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    policeReportelected(event) {
      this.policeReport = event.target.files[0];
    }
  }
});
